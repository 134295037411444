body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

header {
    position: fixed;
    width: 100%;
    background: #761a1a;
    color: white;
    padding: 1rem;
    z-index: 1000;
}

header ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

header ul li {
    margin: 0 1rem;
}

header ul li a {
    color: white;
    text-decoration: none;
}

.full-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 2rem;
    text-align: center;
}

footer {
    background: #333;
    color: white;
    text-align: center;
    padding: 1rem;
}

