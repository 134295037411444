.App {
  text-align: center;
}

body {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: hidden;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Example styles for .App-link */
.App-link {
  color: #61dafb;
}

/* Example keyframes for logo spinning animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 400px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 100%;
}
.gmap_iframe {
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .mapouter {
    width: 75%;
    height: 400px;
  }
}

@media (min-width: 1024px) {
  .mapouter {
    width: 50%;
    height: 400px;
  }
}
